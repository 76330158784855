.why_detail{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255, 50%);
    background-image: linear-gradient(135deg, rgb(255, 255, 255, 15%), rgb(21, 25, 31, 0%));
    transition: all ease;
    cursor: pointer;
    z-index: 5;
}

.why_detail:hover {
    filter: drop-shadow(0 0 0px rgb(255, 255, 255, 60%));
    color: rgb(255, 255, 255, 80%);
  }