.trailsText {
    position: relative;
    width: 100%;
    height: 80px;
    line-height: 80px;
    color: black;
    font-size: 6em;
    font-weight: 800;
    will-change: transform, opacity;
    overflow: hidden;
  }
  
  .trailsText > div {
    padding-right: 0.05em;
    overflow: hidden;
  }
  
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  