@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Raleway:wght@200;400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav_link ul li a {
  color: #222121 !important;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.open-font {
  font-family: 'Open Sans', sans-serif;
}

.relway-font {
  font-family: 'Raleway', sans-serif;
}

.totop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(55, 112, 68);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  z-index: 9999;
}

.totop:hover {
  background-color: rgb(208, 160, 165);
  cursor: pointer;
}


/* Full-screen image */
.banner-image {
  width: 100%;
  /* height: auto; */
  max-height: 100%;
  object-fit: cover;
  display: inline-block;
}

.navbar_main .nav_list li a {
  padding: 10px;
  color: #E9E9E9;
}

.logo_main {
  padding: 10px 0px;
}

.logo_main img {
  width: 70%;
}

.banner_main {
  position: relative;
}

.banner_main::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.459);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 1; */
}

.how_to_reach_banner_main {
  position: relative;
}

.how_to_reach_banner_main::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.459);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.banner_content {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 999;
}

.about_how_to_reach_content {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 999;
  line-height: 4cap;
}

.about_banner {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.about_banner span {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
}

.about_banner p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0px;
  font-family: 'Raleway', sans-serif;
}

.about_how_to_reach div {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0px;
  font-family: 'Raleway', sans-serif;
  padding: 10px;
}

.about_how_to_reach h2 {
  color: #fff;
  font-size: 75px;
  font-weight: 500;
  margin-bottom: 50px;
  font-family: 'Caudex', sans-serif;
  letter-spacing: 2.5px;
}

.about_how_to_reach p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
  font-family: 'Raleway', sans-serif;
  line-height: 27px;
  letter-spacing: 2.5px;
}


.banner_btn {
  color: #fff;
  font-size: 22px;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  border: 1.5px solid #fff;
  border-radius: 10px;
  padding: 10px 40px;
  background: linear-gradient(to right, black 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.banner_btn:hover {
  background-position: left bottom;
}

.banner_btn_main {
  margin-top: 35px;
}

.about_img img {
  width: 100%;
}

.about_detail h2 {
  color: #444444;
  font-size: 33px;
  font-weight: 600;
}

.about_detail p {
  color: #444444;
  font-size: 18px;
  letter-spacing: 1px;
}

.why_here_main {
  padding: 50px 0px;
  background-color: #F2F9F8;
}

.third_main {
  padding: 50px 0px;
  background-color: #F2F9F8;
}

.why_detail {
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  padding: 10px;
  margin: 15px 0px 10px;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.gallery_image {
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  padding: 5px;
  margin: 15px 10px 10px;
  height: 90%;
  display: flex;
  justify-content: space-around;
}

.speciality {
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
}

.why_detail p {
  color: #444;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.why_detail img {
  width: 16%;
}

.gallery_image img {
  width: 100%;
  height: 250px;
  border-radius: 6px;
}

.why_head {
  text-align: center;
}

.why_head h1 {
  color: #444444;
  font-size: 36px;
  font-weight: 400;
}

.location_detail {
  text-align: center;
}

.location {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact_head {
  text-align: center;
}

.location_detail h4 {
  color: #444;
  font-weight: 800;
}

.location_detail p {
  color: #444;
}

.collapse.navbar-collapse.nav_link {
  justify-content: end !important;
}

.nav_link ul li a {
  color: #E9E9E9;
}

.nav_link ul li a:hover {
  color: #e0d8d8ea;
  cursor: pointer;
  text-decoration: underline;
}

.navbar-brand img {
  width: 60%;
}

.navbar.nav_main {
  background-color: #000;
}

.nav_main .navbar-nav li {
  padding: 0px 0px;
}

.about_sec {
  padding: 50px 0px;
}

.contact_head {
  margin-bottom: 40px;
}

.location_detail img {
  width: 30%;
}

.footer {
  background-color: #000;
  text-align: center;
  padding: 30px;
}

.footer p {
  color: #fff;
  font-size: 16px;
}

.footer p span {
  color: #1bac91;
}

.contact_main {
  padding-bottom: 30px;
}

.carousel {
  padding-left: 40px;
  padding-right: 40px;
}

.sec-section {
  margin: 2px 50px;
  padding: 3px 5px;
}

.first-section p {
  color: #444444;
  font-size: 20px;
  font-weight: 500;
}

.description {
  font-size: 13.8px !important;
  color: #505050 !important;
  line-height: 20px;
}

@media only screen and (max-width: 767px) {
  .about_banner span {
    font-size: 20px;
  }
  .nav_main button.navbar-toggler {
    background-color: #fff;
    padding: 3px;
  }
  .navbar-brand img {
    width: 38%;
  }

  .about_banner span {
    font-size: 28px;
  }

  .about_banner p {
    font-size: 14px;
  }

  .banner_btn {
    font-size: 12px;
    border-radius: 6px;
  }

  .banner_btn_main {
    margin-top: 10px;
  }

  .about_sec {
    padding: 40px 0px;
  }

  .about_img {
    margin-bottom: 20px;
  }

  .about_detail h2 {
    font-size: 24px;
  }

  .why_head h1 {
    font-size: 24px;
  }

  .why_here_main {
    padding: 30px 0px;
  }

  .location {
    margin-bottom: 30px;
    height: auto;
  }

  .contact_main {
    padding-bottom: 40px;
  }

  .footer {
    padding: 20px;
  }

  .carousel {
    padding-left: 20px;
    padding-right: 20px;
  }
  .px-5.add_map_main
  {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .map_main iframe
  {
    min-height: 350px !important;
    width: 100%;
  }
}


@media only screen and (max-width: 912px) {
  
  .about_how_to_reach_content {
    text-align: center;
    position: relative;
  }

  .how_to_reach_banner_main::after {
    content: '';
    background-color: rgba(15, 15, 15, 0.459);
    position: relative;
  }

  .how_to_reach_banner_main {
    background-color: rgb(242, 249, 248);
  }


  .about_how_to_reach div {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
  }

  .about_how_to_reach h2 {
    font-family: 'Raleway', sans-serif;
    margin-top: 5px;
    color: #313030;
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
  }

  .about_how_to_reach p {
    color: #000;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 1.5px;
    padding-bottom: 25px;
  }

  .nav_main button.navbar-toggler {
    background-color: #fff;
    padding: 3px;
  }

  .sec-section {
    margin: 2px;
    padding: 3px;
  }

  .first-section p {
    font-size: 17px;
    font-weight: 400
  }

  .first-section h2 {
    font-size: 24px;
  }

  .navbar-brand img {
    width: 38%;
  }

  .about_banner span {
    font-size: 28px;
  }

  .about_banner p {
    font-size: 14px;
  }

  .banner_btn {
    font-size: 12px;
    border-radius: 6px;
  }

  .banner_btn_main {
    margin-top: 10px;
  }

  .about_sec {
    padding: 40px 0px;
  }

  .about_img {
    margin-bottom: 20px;
  }

  .about_detail h2 {
    font-size: 24px;
  }

  .why_head h1 {
    font-size: 24px;
  }

  .why_detail p {
    font-size: 15px;
  }

  .why_detail {
    margin: 0px 0px 10px 0px;
    height: 100%;
  }

  .why_here_main {
    padding: 30px 0px;
  }

  .speciality {
    text-align: center;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    padding: 10px;
    margin: 15px 0px;
    min-height: 94%;
  }

  .location {
    margin-bottom: 30px;
    height: auto;
    padding: 30px;
  }

  .contact_main {
    padding-bottom: 40px;
  }

  .footer {
    padding: 20px;
  }

  .abt_listing {
    display: flex;
    margin-bottom: 20px;
  }

  .carousel {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.full {
  /* position: absolute; */
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  display: block;
  min-height: 100%;
  ;
}

figure {
  margin: 0px;
}

.zoom {
  animation: scale 15s linear infinite;
}

@keyframes scale {
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
}

body {
  overflow-x: hidden;
  /* Add this line to prevent horizontal scrolling */
}

.about_detail {
  overflow: hidden;
}


.thing_to_do_title {
  margin-top: 8px;
  font-weight: 900;
  font-size: 23px;
  margin-bottom: 6px;
}

.map_main {
  transition: filter 2s ease-in-out;
  animation: blurAnimation 4s alternate ease-in-out;
}
.map_main iframe
{
  min-height: 600px;
  width: 100%;
}

@keyframes blurAnimation {
  0% {
    filter: blur(1px);
  }

  50% {
    filter: blur(2px);
  }

  75% {
    filter: blur(3px);
  }

  100% {
    filter: blur(5px);
  }
}


@media only screen and (max-width: 450px) {
  .about_banner span {
    font-size: 16px;
  }
  .location_detail h4 h5 p {
    font-size: 5px;
  }
}